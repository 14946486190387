<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div>
          <b-card header-tag="header" footer-tag="footer">
            <b-row slot="header">
              <b-col><h4 class="card-title">Scans</h4></b-col>
              <b-col class="text-right">
                <b-button :to="{ name: 'new-scan' }" variant="primary">Add New</b-button>
              </b-col>
            </b-row>
            <b-card-text>
              <table class="table table-striped table-bordered">
                <thead>
                <td>#</td>
                <td>Name</td>
                <td>Status</td>
                <td>Score</td>
                <td>Created At</td>
                <td>Options</td>
                </thead>
                <tbody>
                <tr v-for="(item, index) in scans"
                    v-bind:key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ item.score }}</td>
                  <td>{{ item.created_at }}</td>
                  <td>
                    <b-button @click="goToScanDetails(item)">
                      <i class="fa fa-info"></i>
                    </b-button>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td colspan="5">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="meta.pagination.total"
                      :per-page="meta.pagination.per_page"
                    ></b-pagination>
                  </td>
                </tr>
                </tfoot>
              </table>
            </b-card-text>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      show: true,
      currentPage: 1,
    };
  },
  watch: {
    currentPage(val) {
      this.$store.dispatch(window.store.GET_SCANS, { page: val }, { root: true });
    },
  },
  computed: {
    scans() {
      return this.$store.state.scans.scans;
    },
    meta() {
      return this.$store.state.scans.meta;
    },
  },
  mounted() {
    this.$store.dispatch(window.store.GET_SCANS, {}, { root: true });
  },
  methods: {
    goToScanDetails(item) {
      this.$router.push({
        name: 'scan',
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>
